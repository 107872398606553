@font-face {
  font-family: 'GT-Cinetype';
  src: url(../fonts/GT-Cinetype/GT-Cinetype-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'GT-Cinetype-Light';
  src: url(../fonts/GT-Cinetype/GT-Cinetype-Light.otf) format('truetype');
}

body {
  font-family: 'GT-Cinetype';
  color: white;
  background-color: black;
}


.app-container {
  right: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;

  .page-logo {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 10;

    img {
      width: 65px;
    }
  }
}

@media only screen and (max-width: 900px) {
}
