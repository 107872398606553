.about-modal-container {
  right: 0;
  bottom: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background: black;
    border: 1px solid white;
    width: 700px;
    height: 550px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button-div {
      height: 60px;
      background-color: black;
      .logo-img {
        position: absolute;
        left: 10px;
        top: 8px;
        width: 80px;
      }
      .close-div {
        position: absolute;
        right: 10px;
        top: 8px;
        cursor: pointer;
  
        img {
          width: 20px;
        }
      }
    }
    .main-description {
      padding: 0px 90px;
      font-family: 'GT-Cinetype-Light';
      font-weight: 100;
      font-size: 14px;
    }
    .gap {
      flex: 1;
    }
    .get-in-touch {
      font-size: 24px;
      font-weight: 800;
    }
    .contact-div {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding: 0px 90px 60px 90px;
      .one-contact {
        padding-left: 50px;
        flex: 1;
        p {
          font-family: 'GT-Cinetype-Light';
          font-size: 12px;
          margin: 0;
        }
      }
    }
  }

  @media screen and (max-width: 900px) , screen and (max-height: 600px) {
    overflow: scroll;
    align-items: unset;
    background-color: black;

    .content {
      background: black;
      border: none;
      width: 100%;
      height: max-content;
      padding-top: 60px;

      .button-div {
        position: fixed;
        right: 0;
        left: 0px;
        top: 0px;
        .logo-img {
          left: 30px;
          top: 30px;
          width: 65px;
        }
        .close-div {
          right: 15px;
          top: 25px;
    
          img {
            width: 25px;
          }
        }
      }
      .main-description {
        padding: 0px 40px;
      }
      .get-in-touch {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 10px;
      }
      .contact-div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 60px;
        .one-contact {
          padding-left: 0px;
          width: 140px;
          margin-top: 20px;
        }
      }
    }
  }
}
